import styled from '@emotion/styled'

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin: 0 auto;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
  }
`;

export const VideoFinderWrapper = styled.div`
  [data-oembed-type="video"] {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 0 auto;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%!important;
      height: 100%!important;
    }
  }
`;