export const getVideoProvider = (url) => {
  if (url.search('youtube') !== -1) {
    return 'youtube'
  } else if (url.search('vimeo') !== -1) {
    return 'vimeo'
  }
}

export const getVideoId = (provider, url) => {
  if (provider === 'youtube' || provider === 'YouTube') {
    return getYoutubeVideoId(url)
  } else if (provider === 'vimeo' || provider === 'Vimeo') {
    return getVimeoVideoId(url)
  }
}

function getYoutubeVideoId(url) {
  var regExp = /^.*((youtu.be\/)|(\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}
function getVimeoVideoId(url) {
  let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
  let match = url.match(regEx);
  return (match && match.length === 7) ? match[6] : false;
}