import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import Modal from 'react-modal'
import Slider from '../Slick/Slider'
import { handleModalBanner } from '../../actions/modal'
import Prev from '../../../static/prev.svg'
import Next from '../../../static/next.svg'
import "../../styles/modal.css";

const ModalWrapper = styled(Modal)`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  ${'' /* background: ${props => props.theme.colors.background}; */}
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    align-items: start;
    padding-top: 2rem;
  }
  justify-content: center;
  overflow: hidden;
`
const ImagesContainer = styled.div`
  max-width: 100vw;
  text-align: center;
  overflow: hidden;
  h1, h2 {
    display: inline-block;
    font-size: 13px;
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    margin: 0;
  }
  h1 {
    font-weight: 600;
  }
  h2 {
    font-weight: 500;
  }
  p {
    color: ${props => props.theme.colors.grey};
    font-size: 17px;
    text-align: center;
    margin-bottom: 0;
    span {
      font-weight: normal;
      display: inline-block;
      font-size: 12px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-top: 10px;
  }
  > div {
    > ul {
      bottom: -40px;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        display: none!important;
      }
    }
  }
`
const CloseModal = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none!important;
  z-index: 3;
  
  img {
    max-width: 12px;
    filter: invert(1);
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: .8rem .1rem .1rem .1rem;
    img {
      max-width: 10px;
    }
  }
`

const ModalArtwork = ({
  images,
  initialSlide,
  modalBanner,
  handleModalBanner
}) => {
  const [state, setState] = useState({
    currentSlide: initialSlide
  });
  
  const modalStyles = { overlay: {} };
  modalStyles.overlay["zIndex"] = 2;
  
  if (images) {
    if (typeof state.currentSlide === 'undefined' && modalBanner) {
      setState({currentSlide: initialSlide});
    } else if (typeof state.currentSlide !== 'undefined' && !modalBanner) {
      setState({ currentSlide: undefined })
    }
  }
  
  return (
    <ModalWrapper
      isOpen={modalBanner}
      backdropColor={'white'}
      backdropOpacity={1}
      closeTimeoutMS={200}
      ariaHideApp={false}
      onRequestClose={() => handleModalBanner(false)}
      shouldCloseOnOverlayClick={true}
      style={modalStyles}
    >
      <div className="modal-container">
        <CloseModal onClick={() => handleModalBanner(false)}>
          <img src={Next} alt="suivant" />
          <img src={Prev} alt="précédent" />
        </CloseModal>

        <ImagesContainer>
          <Slider images={images} currentSlide={state.currentSlide} />
        </ImagesContainer>
      </div>
    </ModalWrapper>
  );
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalArtwork);