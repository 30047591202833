import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import { handleModalBanner } from '../actions/modal'
import ModalArtwork from '../components/Modal/ModalArtwork'
import SEO from '../components/SEO'

const List = styled.ul`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0;
  list-style-type: none;
  margin-left: 0;
  flex: 1;
  justify-content: center;
  
  &:after {
    display: block;
    content: "";
    float: none;
    clear: both;
  }
`

const Item = styled.li`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  position: relative;
  float: left;
  margin-bottom: 0;
  width: 250px;
  height: 250px;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xsLandscape}) {
    width: 25%; 
    height: auto;
    padding-top: 25%;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    width: 100%; 
    height: auto;
    padding-top: 100%;
    margin-bottom: 1.5rem;
  }
  
  > div {
    position: absolute!important;
    display: flex!important;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important;
    align-items: center;
    justify-content: center;
    img {
      object-fit: cover;
      max-width: 450px;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        max-width: none;
      }
    }
  }
`

const Credits = styled.div`
  margin: 4rem 0 0;
  p {
    color: ${props => props.theme.colors.grey};
    text-align: left;
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    font-size: 20px;
  }
`;

const Gallery = ({ location, data: { gallery, credits }, modalBanner, handleModalBanner }) => {
  const mediaGallery = gallery.data.galerie;
  
  return (
    <React.Fragment>
      <SEO 
        title={`Alexx & Mooonshiners | Photos et Vidéos`}
        desc={`Photos et vidéos de concerts`}
        pathname={location.pathname}
      />
      <Header location={location} />
      <MainWrapper>
        <List>
          {
            mediaGallery.map((item, index) => {
              return (
                <Item 
                  key={`media-${index}`}
                  onClick={() => handleModalBanner({
                    modalBanner: !modalBanner,
                    initialSlide: index
                  })}
                >
                  <div>
                    <img alt={`media-${index}`} src={
                      item.video.thumbnail_url || item.photo.localFile.childImageSharp.fluid.srcWebp
                    } />
                  </div>
                </Item>
              )
            })
          }
        </List>
        <Credits dangerouslySetInnerHTML={{ __html: credits.data.texte.html }} />
      </MainWrapper>
      <ModalArtwork images={mediaGallery} />
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);

export const pageQuery = graphql`
  query GalleryImages {
    gallery: prismicGaleri {
      data {
        galerie {
          photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          video {
            embed_url
            thumbnail_url
          }
        }
      }
    }
    credits: prismicCreeditsphotos {
      data {
        texte {
          html
        }
      }
    }
  }
`