import React from 'react';
import styled from '@emotion/styled'
import Prev from '../../../static/prev.svg';
import Next from '../../../static/next.svg';


const PrevButton = styled.button`
  left: 2rem;
  height: 40px!important;
  z-index:3;
  &:before {
    display: none!important;
  }
  img {
    filter: invert(1);
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`
const NextButton = styled.button`
  right: 2rem;
  height: 40px!important;
  z-index:3;
  &:before {
    display: none!important;
  }
  img {
    filter: invert(1);
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`

export const CustomPrevArrow = ({ className, onClick }) => (
  <PrevButton type="button" className={className} onClick={onClick}>
    <img src={Prev} alt="prev" />
  </PrevButton>
)

export const CustomNextArrow = ({ className, onClick }) => (
  <NextButton type="button" className={className} onClick={onClick}>
    <img src={Next} alt="next" />
  </NextButton>
)