import React from 'react'
import styled from '@emotion/styled'
import Slider from 'react-slick'
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { VideoContainer } from '../../containers/Media'
import YouTube from 'react-youtube';
import { getVideoId } from '../../utils/mediaHelpers'

const Img = styled.img`
  display: block;
  margin: 0 auto;
  max-height: calc(100vh - 50px);
`;
const PostContainer = styled.div`
  margin: 0 auto;
  max-width: 800px!important;
`;
const videoOpts = {
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

export default class extends React.Component {

  componentDidMount() {
    window.addEventListener('keydown', this.keyPressHandler);
  }
  
  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPressHandler);
  }

  keyPressHandler = (e) => {
    if (e.keyCode === 39) {
      this.next();
    } else if(e.keyCode === 37) {
      this.previous();
    }
  }

  next = () => {
    this.slider.slickNext();
  }

  previous = () => {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      adaptiveHeight: true,
      focusOnSelect: true,
      accessibility: true,
      focusOnChange: true,
      infinite: true,
      speed: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.props.currentSlide || 0,
      dots: false,
      fade: false
    };
    return (
      <React.Fragment>
        {
          this.props.images && (
            <Slider ref={c => (this.slider = c)} {...settings}>
              { this.props.images && this.props.images.map((image, index) => {
                return (
                  <div key={`image-${index}`}>
                    {
                      image.photo.localFile ? (
                        <Img 
                          key={index} 
                          alt="Alexx & the Mooonshiners" 
                          src={image.photo.localFile.childImageSharp.fluid.srcWebp}
                        />
                      ) : (
                        <PostContainer>
                          <VideoContainer>
                            <YouTube 
                              videoId={getVideoId('YouTube', image.video.embed_url)}
                              opts={videoOpts} 
                            />
                          </VideoContainer>
                        </PostContainer>
                      )
                    }
                  </div>
                )}
              )}
            </Slider>
          )
        }
      </React.Fragment>
    )
  }
}